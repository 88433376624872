import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useEmployeeList(isTemplate) {
  const refDocumentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
    { key: 'date', label: i18nT(`Date`), sortable: true },
    { key: 'amount', label: i18nT(`Amount`), sortable: true },
    { key: 'DebtorName', label: i18nT(`Recipient / Sender`), sortable: true },
    { key: 'name', label: i18nT(`Description`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
    { key: 'bank_account', label: i18nT(`Bank`), sortable: true },


    { key: 'currency', label: i18nT(`Currency`), sortable: true },
    { key: 'status', label: i18nT(`Status`), sortable: true },
    {
      key: 'action',
      label: i18nT(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      stickyColumn: true
    },
  ]
  const perPage = ref(10)
  const totalDocuments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const transactions = ref([])
  const bankAccount = ref('')
  const currency = ref('')
  const matchedStatus = ref('')
  const startDate = ref('');
  const endDate = ref('');

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value
      ? refDocumentListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    }
  })

  const refetchData = () => {
    refDocumentListTable.value.refresh()
  }

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      bankAccount,
      currency,
      matchedStatus,
      startDate,
      endDate,
    ],
    () => {
      refetchData()
    }
  )

  const fetchDocuments = (ctx, callback) => {
    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value,
      start_date: startDate.value,
      end_date: endDate.value,
    }

    if (bankAccount.value != null) {
      params['filters[bank_account]'] = bankAccount.value
    }

    if (currency.value != null) {
      params['filters[currency]'] = currency.value
    }
    if (matchedStatus.value != null) {
      params['filters[matched]'] = matchedStatus.value
    }
    if(startDate.value != ''){
      params['filters[start_date]'] = startDate.value;
    }
    if(endDate.value != ''){
      params['filters[end_date]'] = endDate.value;
    }


    axios
      .get(`transactions`, {
        params,
      })
      .then(({ data }) => {
        
        if (data.data.bank_transactions) {
          transactions.value = data.data.bank_transactions
          callback(data.data.bank_transactions)
        } else {
          transactions.value = []
          callback([])
        }

        totalDocuments.value = data.data.pagination.records_count
      })
  }

  return {
    fetchDocuments,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,
    totalDocuments,
    bankAccount,
    currency,
    transactions,
    matchedStatus,
    startDate,
    endDate,
    refetchData,
  }
}
